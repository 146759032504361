import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogsService {

  constructor(private http: HttpClient) { }

  getAllBlogs() {
    const url = `${environment.apiUrl}blog/list`;
    return this.http.get(url);
  }

  getBlogDetails(blogId: string) {
    const url = `${environment.apiUrl}blog/detail/${blogId}`;
    return this.http.get(url);
  }

  likeBlog(blogId: string) {
    const url = `${environment.apiUrl}blog/addlike/${blogId}`;
    return this.http.post(url, {});
  }

}
